import axios from 'axios';
import * as Font from 'expo-font';
import { useURL } from 'expo-linking';
import * as Linking from 'expo-linking';
import * as R from 'ramda';
import React, { useEffect, useReducer, useState } from 'react';
import {
  Image,
  ActivityIndicator,
  FlatList,
  ScaledSize,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  Platform,
} from 'react-native';
import RenderHtml, { defaultSystemFonts } from 'react-native-render-html';
import useSWR from 'swr';

const fetcher = (url: string) => {
  return axios({ baseURL: 'https://apps-service-dev.bravostudio.app', url }).then((res) => res.data);
};

type AppStateAction =
  | { type: 'SetAppData'; appData: any }
  | { type: 'SetRemoteData'; remoteData: any }
  | { type: 'SetAppAndScreenId'; appId: string; screenId: string }
  | { type: 'SetViewport'; viewport: ScaledSize }
  | { type: 'SetScaleFactor'; factor: number }
  | { type: 'SetTopBarHeight'; height: number }
  | { type: 'RegisterFontLoad'; fontId: string; fontFamily: string };

interface AppState {
  appId: string;
  screenId: string;
  app: any;
  screen: any;
  screens: Record<string, any>;
  styles: Record<string, any>;
  assets: Record<string, any>;
  fonts: Record<string, any>;
  remote: any;
  viewport: ScaledSize;
  layout: any;
  scaleFactor: number;
  loadedFonts: Record<string, string>;
}

const selectScreen = (state: AppState) => {
  const screenId = state.screenId || R.values(state.screens || {}).find((s) => s.start)?.id;
  return state.remote?.data.id === screenId ? state.remote?.data : state.screens?.[screenId];
};

interface BravoComponentProps {
  component: any;
  state: AppState;
  dispatch: (action: AppStateAction) => void;
  parentDimensions: { width: number; height: number };
  children?: any;
}

const argbAlphaToOpacity = (color) => {
  const alpha = parseInt(color.slice(1, 3), 16);
  return alpha / 255.0;
};

const argbToRgba = (color) => {
  return color.replace(/#(\w{2})(\w{6})/, '#$2$1');
};

const convertStyle = (style, state) => {
  const result = {} as any;

  if (!style) return null;

  if (style.width) result.width = style.width * state.viewport.width * 0.01;

  if (style.aspectRatio && style.width) {
    result.height = (style.width * state.viewport.width * 0.01) / style.aspectRatio;
  } else {
    if (style.height) result.height = style.height * state.viewport.height * 0.01;
  }

  if (style.positioning) {
    result.top = `${style.positioning.top}%`;
    result.bottom = `${style.positioning.bottom}%`;
    result.left = `${style.positioning.left}%`;
    result.right = `${style.positioning.right}%`;
    result.position = 'absolute';
  }

  if (style.backgroundColor && style.backgroundColor !== '#ffffffff') {
    result.backgroundColor = argbToRgba(style.backgroundColor);
  }

  if (style.color) result.color = argbToRgba(style.color);
  if (style.lineHeightPx) result.lineHeight = style.lineHeightPx * state.scaleFactor;
  if (style.fontSize) result.fontSize = style.fontSize * state.scaleFactor;
  if (style.fontWeight) result.fontWeight = `${style.fontWeight}`;
  if (style.opacity) result.opacity = style.opacity * 0.01;
  if (style.fontId) result.fontFamily = state.loadedFonts[style.fontId] || 'sans-serif';
  if (style.borderRadius) {
    result.borderRadius = style.borderRadius * state.scaleFactor;
    result.overflow = 'hidden';
  }
  if (style.cornerRadii) {
    result.borderTopLeftRadius = style.cornerRadii[0] * state.scaleFactor;
    result.borderTopRightRadius = style.cornerRadii[1] * state.scaleFactor;
    result.borderBottomRightRadius = style.cornerRadii[2] * state.scaleFactor;
    result.borderBottomLeftRadius = style.cornerRadii[3] * state.scaleFactor;
    delete result.borderRadius;
    result.overflow = 'hidden';
  }

  if (style.borderWidth) result.borderWidth = style.borderWidth * 2.875; // ??? This is wrong. It works on some bordered elements but not others
  if (style.borderColor) result.borderColor = argbToRgba(style.borderColor);

  if (style.rotation) result.transform = [{ rotate: `${style.rotation}deg` }];

  if (style.textCase === 'upper') {
    result.textTransform = 'uppercase';
  }

  if (style.textCase === 'lower') {
    result.textTransform = 'lowercase';
  }

  if (style.letterSpacing) {
    result.letterSpacing = style.letterSpacing;
  }

  const missed = R.omit(
    [
      'isFixed', // true|false - We're going to ignore this one for now
      'behavior', // "fixed" - we'll ignore this one too
      'scaleMode', // "fill" - and this one
      'width',
      'height',
      'positioning',
      'color',
      'backgroundColor',
      'textAlign',
      'lineHeightPx',
      'fontSize',
      'fontWeight',
      'opacity',
      'fontId',
      'borderRadius',
      'cornerRadii',
      'borderWidth',
      'borderColor',
      'rotation',
      'textCase',
      'aspectRatio',
      'letterSpacing',
      'verticalPosition',
    ],
    style,
  );

  if (R.keys(missed).length > 1) {
    console.log('Missing style handler', missed);
  }

  return result;
};

const BravoTextComponent = ({ component, state, parentDimensions }) => {
  const bravoStyle = state.styles[component.styleId];
  const style = convertStyle(bravoStyle, state);
  style.overflow = 'hidden';
  style.display = 'table-cell';

  if (bravoStyle.textAlign) {
    style.textAlign = bravoStyle.textAlign;
  }

  if (bravoStyle.verticalPosition) {
    style.verticalAlign = { 'from-top': 'top', center: 'middle', 'from-bottom': 'bottom' }[bravoStyle.verticalPosition];
  }

  if (!component.text?.en) {
    console.log('Missing text', component);
  }

  const html = `<div style="font-family: '${style.fontFamily}'; font-size: ${style.fontSize}px; font-weight: ${style.fontWeight}; color: ${style.color}">${component.text?.en}</div>`;
  const fonts = [...R.values(state.loadedFonts), ...defaultSystemFonts] as string[];

  return (
    <View style={style}>
      <RenderHtml contentWidth={style.width || parentDimensions.width} source={{ html }} systemFonts={fonts} />
    </View>
  );

  //return <Text selectable={false} style={style}>{component.text?.en}</Text>
};

const BravoBackgroundContainerComponent = ({ component, state, dispatch, parentDimensions }: BravoComponentProps) => {
  const style = convertStyle(state.styles[component.styleId], state);
  style.position = 'absolute';
  style.height = state.viewport.height * 10;

  return (
    <View style={style}>
      {(component.components || []).map((c) => (
        <BravoComponent
          key={c.id}
          component={c}
          state={state}
          dispatch={dispatch}
          parentDimensions={{
            width: style.width || parentDimensions.width,
            height: style.height || parentDimensions.height,
          }}
        />
      ))}
    </View>
  );
};

const BravoTopbarContainerComponent = ({ component, state, dispatch, parentDimensions }: BravoComponentProps) => {
  const style = convertStyle(state.styles[component.styleId], state);
  style.position = 'absolute';
  style.zIndex = 10;
  style.top = 0;

  return (
    <View style={style}>
      {(component.components || []).map((c) => (
        <BravoComponent
          key={c.id}
          component={c}
          state={state}
          dispatch={dispatch}
          parentDimensions={{
            width: style.width || parentDimensions.width,
            height: style.height || parentDimensions.height,
          }}
        />
      ))}
    </View>
  );
};

const BravoColorComponent = ({ component, state }: BravoComponentProps) => {
  const style = convertStyle(state.styles[component.styleId], state);
  return <View style={style}></View>;
};

const BravoImageComponent = ({ component, state }: BravoComponentProps) => {
  const style = convertStyle(state.styles[component.styleId], state);
  const asset = state.assets[component.assetId];
  return <Image style={style} source={{ uri: asset.url }}></Image>;
};

const BravoComponent = ({ component, state, dispatch, children, parentDimensions }: BravoComponentProps) => {
  const style = state.styles?.[component?.styleId];

  switch (component?.type) {
    case 'container:top-bar':
      return (
        <BravoTopbarContainerComponent
          component={component}
          state={state}
          dispatch={dispatch}
          parentDimensions={parentDimensions}
        />
      );
    case 'container:background':
      return (
        <BravoBackgroundContainerComponent
          component={component}
          state={state}
          dispatch={dispatch}
          parentDimensions={parentDimensions}
        />
      );
    case 'component:color':
      return (
        <BravoColorComponent
          component={component}
          state={state}
          dispatch={dispatch}
          parentDimensions={parentDimensions}
        />
      );
    case 'component:text':
      return <BravoTextComponent component={component} state={state} parentDimensions={parentDimensions} />;
    case 'component:svg':
    case 'component:image':
      return (
        <BravoImageComponent
          component={component}
          state={state}
          dispatch={dispatch}
          parentDimensions={parentDimensions}
        />
      );
    default:
    //console.log(`Missing ${component?.type}`, component);
  }

  return (
    <View style={convertStyle(style, state)}>
      {children
        ? children
        : component?.components?.map((c) => {
            return (
              <BravoComponent
                key={c.id}
                component={c}
                state={state}
                dispatch={dispatch}
                parentDimensions={parentDimensions}
              />
            );
          })}
    </View>
  );
};

const appStateReducer = (state: AppState, action: AppStateAction): AppState => {
  if (R.isEmpty(state)) {
    state = {
      appId: '',
      screenId: '',
      app: null,
      screen: null,
      screens: {},
      styles: {},
      assets: {},
      fonts: {},
      loadedFonts: {},
      remote: null,
      layout: null,
      scaleFactor: 1.0,
      viewport: null,
    };
  }

  switch (action.type) {
    case 'SetAppData': {
      const newState = {
        ...state,
        app: action.appData,
        screens: R.indexBy(R.prop('id'), action.appData.app.data.pages),
      };

      newState.screen = selectScreen(newState);
      newState.styles = R.indexBy(R.prop('id'), [
        ...(newState.app.app.styles || []),
        ...(newState.remote?.styles || []),
      ]);
      newState.assets = R.indexBy(R.prop('id'), [
        ...(newState.app.app.assets || []),
        ...(newState.remote?.assets || []),
      ]);
      newState.fonts = R.indexBy(R.prop('id'), [...(newState.app.app.fonts || []), ...(newState.remote?.fonts || [])]);

      return newState;
    }
    case 'SetRemoteData': {
      const newState = {
        ...state,
        remote: action.remoteData,
      };

      newState.screen = selectScreen(newState);
      newState.styles = R.indexBy(R.prop('id'), [
        ...(newState.app.app.styles || []),
        ...(newState.remote?.styles || []),
      ]);
      newState.assets = R.indexBy(R.prop('id'), [
        ...(newState.app.app.assets || []),
        ...(newState.remote?.assets || []),
      ]);
      newState.fonts = R.indexBy(R.prop('id'), [...(newState.app.app.fonts || []), ...(newState.remote?.fonts || [])]);

      return newState;
    }
    case 'SetAppAndScreenId': {
      const newState = {
        ...state,
        appId: action.appId,
        screenId: action.screenId,
      };

      newState.screen = selectScreen(newState);

      return newState;
    }

    case 'SetViewport': {
      return {
        ...state,
        viewport: action.viewport,
      };
    }

    case 'SetScaleFactor': {
      return {
        ...state,
        scaleFactor: action.factor,
      };
    }

    case 'RegisterFontLoad':
      return {
        ...state,
        loadedFonts: {
          ...state.loadedFonts,
          [action.fontId]: action.fontFamily,
        },
      };
  }
};

export default function App() {
  const url = useURL();

  const viewport = useWindowDimensions();
  const [state, dispatch] = useReducer(appStateReducer, {} as AppState);

  const app = useSWR(state.appId && `/devices/apps/${state.appId}`, fetcher);
  const remote = useSWR(state.screens?.[state.screen?.id]?.remote, fetcher);

  useEffect(() => {
    if (!viewport) return;
    dispatch({ type: 'SetViewport', viewport });
  }, [viewport]);

  useEffect(() => {
    if (!app.data) return;
    dispatch({ type: 'SetAppData', appData: app.data });
  }, [app.data]);

  useEffect(() => {
    if (!remote.data) return;
    dispatch({ type: 'SetRemoteData', remoteData: remote.data });
  }, [remote.data]);

  useEffect(() => {
    const parsedUrl = url && Linking.parse(url);
    const appId = parsedUrl?.queryParams?.appId || '01FEE3AHMGXT0N9XVB066M35E3';
    const screenId = parsedUrl?.queryParams?.screenId || '01FEE3AHQMVBWF4BXKD0DVH3JS';

    dispatch({ type: 'SetAppAndScreenId', appId, screenId });
  }, [url]);

  useEffect(() => {
    if (Platform.OS !== 'web') return;
    window?.addEventListener('message', (message) => {
      if (message.data !== 'bravorefresh') return;
      app.mutate(app.data, true);
      remote.mutate(remote.data, true);
    });
  }, []);

  useEffect(() => {
    if (!state.fonts) return;

    R.values(state.fonts).forEach((font) => {
      if (state.loadedFonts?.[font.id]) return;
      const fontFamily = `BRAVO-${font.id}`;
      Font.loadAsync(fontFamily, font.url)
        .then(() => {
          dispatch({ type: 'RegisterFontLoad', fontId: font.id, fontFamily });
        })
        .catch(() => {
          Font.unloadAsync(fontFamily, font.url);
          dispatch({ type: 'RegisterFontLoad', fontId: font.id, fontFamily: `sans-serif` });
        });
    });
  }, [state.fonts]);

  let topBarHeight = 0;
  const topBar = state.screen?.topBar;
  if (topBar && state.styles) {
    const topBarStyle = state.styles[topBar.styleId];
    if (topBarStyle.aspectRatio) {
      topBarHeight = (topBarStyle.width * (viewport?.width || 1) * 0.01) / topBarStyle.aspectRatio;
    } else {
      topBarHeight = topBarStyle.height * (viewport?.width || 1) * 0.01 || 0;
    }
  }

  useEffect(() => {
    if (!state.screen || !state.styles || !viewport) return;
    const screenStyle = state.styles?.[state.screen?.styleId];
    const width = screenStyle?.originalSize?.[0] || 320;
    dispatch({ type: 'SetScaleFactor', factor: (viewport.width / width) * 0.95 });
  }, [state.screen, state.styles, viewport]);

  return !app.data ? (
    <View style={styles.loading}>
      <ActivityIndicator />
    </View>
  ) : (
    <View style={styles.container}>
      <React.Fragment>
        <BravoComponent
          component={state.screen?.background}
          state={state}
          dispatch={dispatch}
          parentDimensions={{ width: viewport.width, height: viewport.height }}
        />
        <BravoComponent
          component={state.screen?.topBar}
          state={state}
          dispatch={dispatch}
          parentDimensions={{ width: viewport.width, height: viewport.height }}
        />
        <FlatList
          style={{ position: 'absolute', top: topBarHeight, height: (viewport?.height || 0) - topBarHeight }}
          data={state.screen?.body || []}
          renderItem={({ item }) => (
            <BravoComponent
              component={item}
              state={state}
              dispatch={dispatch}
              parentDimensions={{ width: viewport.width, height: (viewport?.height || 0) - topBarHeight }}
            />
          )}
          keyExtractor={(c) => c.id}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        />
      </React.Fragment>
    </View>
  );
}

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    backgroundColor: '#202125',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
